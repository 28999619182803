import { theme } from '../../styles/theme';

interface CheckmarkProps {
  className?: string;
}

const Checkmark = ({ className = 'w-5 h-5' }: CheckmarkProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 30"
    className={className}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={theme.palette.secondary}
      strokeWidth="6"
      d="M2 12.432L16.95 25.38 36.486 2"
    />
  </svg>
);

export default Checkmark;
