import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import Checkmark from './icons/Checkmark';

type CheckButtonStyledProps = {
  readonly selected: boolean;
};

export const CheckButtonStyled = styled.button<CheckButtonStyledProps>`
  font-family: sans-serif;
  font-size: 12pt;
  z-index: 9;
  cursor: pointer;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  color: ${theme.palette.grey};
  border: 0;
  border-radius: 2em;
  background-color: ${({ selected }) =>
    selected ? theme.palette.primary : theme.palette.grey};
  margin-bottom: 10px;
  outline: none;
  position: relative;
  flex-direction: row;
  justify-content: space-between;

  &:active,
  &:hover {
    background-color: ${theme.palette.primary};
  }

  svg {
    opacity: ${({ selected }) => (selected ? '1' : '0')};
    transition: opacity ease-in-out 0.25s;
  }
`;

export const CheckButton = ({ buttonText, selected, onClick }) => (
  <CheckButtonStyled {...{ selected, onClick }}>
    <span>{buttonText}</span>
    <Checkmark />
  </CheckButtonStyled>
);

CheckButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

CheckButton.defaultProps = { onClick: () => {} };
