import React, { useState, useEffect, useContext } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import nookies from 'nookies';
import { useRouter } from 'next/router';
import Button from '../components/Button';
import FloatingButton from '../components/FloatingButton';
import { PageElements, P, SmallP } from '../components/PageElements';
import {
  setLocale as setCookieLocale,
  getCountryCookie,
} from '../utils/cookie';
import { CheckButton } from '../components/CheckButton';
import { countries } from '../components/i18n';
import { isServerRender } from '../utils/url';
import { PeikkoContext } from '../contexts/PeikkoContext';

const LocaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Locale = ({ countryCode }) => {
  const { i18n } = useContext(PeikkoContext);
  const [locale, setLocale] = useState<string>(null);
  const country = countries.find((c) => {
    return countryCode === c.shortname;
  });

  const updateLocale = (newLocale: string): void => {
    setLocale(newLocale);
    i18n.updateLocale(newLocale);
    setCookieLocale(newLocale);
  };

  useEffect(() => {
    if (country?.languages?.length === 1 && !locale) {
      const newLocale: string = country.languages[0].locale;
      setLocale(newLocale);
      i18n.updateLocale(newLocale);
      setCookieLocale(newLocale);
    }
  }, [country]);

  const router = useRouter();

  useEffect(() => {
    router.prefetch('/onboarding/1');
  }, []);

  if (!country) {
    return null;
  }

  return (
    <>
      <PageElements>
        <div className="!mb-28 w-full">
          <P role="heading" aria-level={1}>
            {i18n.t('locale.header')}
          </P>
          <SmallP style={{ marginBottom: '2em' }}>
            {i18n.t('locale.subheader')}
          </SmallP>
          <LocaleContainer>
            {country.languages.map((l) => (
              <CheckButton
                key={l.locale}
                onClick={(): void => updateLocale(l.locale)}
                selected={locale ? locale === l.locale : false}
                buttonText={l.name}
              />
            ))}
          </LocaleContainer>
        </div>
      </PageElements>
      <FloatingButton>
        <Link href="/" replace>
          <Button disabled={!locale}>{i18n.t('locale.continue')}</Button>
        </Link>
      </FloatingButton>
    </>
  );
};

interface InitialProps {
  countryCode?: string;
}

Locale.getInitialProps = (ctx): InitialProps => {
  const getCountryUsingCookie = (): string => {
    const countryCookie: string = getCountryCookie();
    const countryObj = countries.find((c) => countryCookie === c.shortname);

    return countryObj ? countryObj.shortname : null;
  };

  const country = isServerRender(ctx)
    ? ctx.query.country || nookies.get(ctx).country
    : getCountryUsingCookie();

  if (!country) {
    if (isServerRender(ctx)) {
      ctx.res.writeHead(302, { Location: '/country' });
      ctx.res.end();
      return {};
    }
    Router.push('/country');
    return {};
  }

  return { countryCode: country };
};

export default Locale;
