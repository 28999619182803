import styled from 'styled-components';
import PropTypes from 'prop-types';
import { width } from '../styles/theme';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 1.5em;
  left: 0;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: ${width.container}px;
  margin: 0 auto;
  padding-bottom: 15px;
  text-align: center;

  @media (max-width: ${width.phone}px) {
    text-align: center;
    width: 100%;
  }

  @media (min-width: ${width.tablet}px) {
    text-align: right;
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (min-width: ${width.desktop}px) {
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: ${width.container}px) {
    width: 100%;
  }
`;

const FloatingButton = ({ children, ...rest }) => (
  <ButtonContainer {...rest}>
    <ButtonWrapper>{children}</ButtonWrapper>
  </ButtonContainer>
);

FloatingButton.propTypes = { children: PropTypes.node.isRequired };

export default FloatingButton;
